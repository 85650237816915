import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, NgZone, AfterViewChecked, PLATFORM_ID, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import * as $ from 'jquery';
import { LinkService } from '../../_services/langhfre.service';
declare function ire(string,{}): any;
@Component({
  selector: 'app-try-ademo',
  templateUrl: './try-ademo.component.html',
  styleUrls: ['./try-ademo.component.scss']
})
export class TryADemoComponent implements OnInit, AfterViewInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private LinkService: LinkService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Free Online AI Grammar Checker Tool for Academic Writing – Trinka');
    this.metaTagService.updateTag({ name: 'description', content: "Trinka's free online AI grammar checker fixes your English grammar, spelling, punctuation, structure, and more. Specially designed for academic and formal writing." });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Free Online AI Grammar Checker Tool for Academic Writing – Trinka' });
    this.metaTagService.updateTag({ property: 'og:description', content: "Trinka's free online AI grammar checker fixes your English grammar, spelling, punctuation, structure, and more. Specially designed for academic and formal writing." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/try-a-demo' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/try-a-demo' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/try-a-demo' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/try-a-demo' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/try-a-demo' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/try-a-demo' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/try-a-demo' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/try-a-demo' });
    if (isPlatformBrowser(this.platformId)) {
      //$.getScript('../../../assets/js/wisepop.js', function () { });
      $.getScript('../../../assets/js/hubslider.js', function () { });
      $.getScript('../../../assets/js/owl.carousel.min.js', function () { });
    }
    ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      $.getScript('../../../assets/js/home_client_slider.js', function () { });
      $.getScript('../../../assets/js/about_us_testimonial_silders.js', function () { });
      $('#closedPopup').click(function () {
        $('iframe.youtubeVideoForTryDemo').remove()
      });

      $('#VewiVideoSection').click(function () {
        if (!$('#iframe').length) {
          $('#iframeHolder').html('<iframe class="youtubeVideoForTryDemo" id="iframe" src="https://www.youtube.com/embed/8dmdUUvVj3Q" width="100%" height="450"></iframe>');
        }
      });

    }
  }

  

}

// $('.pause-video').click(function(){
//   $('.youtube-iframe').each(function(){
//       var el_src = $(this).attr("src");
//       $(this).attr("src",el_src);
//   });
//   $('iframe.youtube-iframe').remove()
//   });

//     // Load Exp editor video in popup
// $('#exp-editor-btn').click(function () {
// $('<iframe id="youtube-video" class="youtube-iframe" width="100%" height="315" src="https://www.youtube-nocookie.com/embed/A5Lms0Si1N0?rel=0&wmode=Opaque&enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>').appendTo('#exp-editor-vdo');
// });
// $('#ps-submit-btn').click(function () {
// $('<iframe id="youtube-video" class="youtube-iframe" width="100%" height="315" src="https://www.youtube.com/embed/SwB-AOSSwoY?rel=0&wmode=Opaque&enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>').appendTo('#ps-submit-vdo');
// });