import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import * as $ from 'jquery';
import { LinkService } from '../../../_services/langhfre.service';
declare function ire(string,{}): any;
@Component({
  selector: 'app-personal-dictionary',
  templateUrl: './personal-dictionary.component.html',
  styleUrls: ['./personal-dictionary.component.scss']
})
export class PersonalDictionaryComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private LinkService: LinkService
  ) { }
  ngOnInit() {
    this.titleService.setTitle('Customize Trinka with your own Personal Dictionary');
    this.metaTagService.updateTag({ name: 'description', content: "Add words to your own Personal Dictionary that Trinka doesn't recognize such as brand names or new techniques to avoid spelling alerts." });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Customize Trinka with your own Personal Dictionary' });
    this.metaTagService.updateTag({ property: 'og:description', content: "Add words to your own Personal Dictionary that Trinka doesn't recognize such as brand names or new techniques to avoid spelling alerts." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/features/personal-dictionary' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/Personal-Dictionary.png' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Customize Trinka with your own Personal Dictionary' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Add words to your own Personal Dictionary that Trinka doesn't recognize such as brand names or new techniques to avoid spelling alerts." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/Personal-Dictionary.png' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/features/personal-dictionary' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/features/personal-dictionary' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/features/personal-dictionary' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/features/personal-dictionary' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/features/personal-dictionary' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/features/personal-dictionary' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/features/personal-dictionary' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/features/personal-dictionary' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/features/personal-dictionary' });
    if (isPlatformBrowser(this.platformId)) {
      // $.getScript('../../../assets/js/particles.min.js', function(){});
      //$.getScript('../../../assets/js/wisepop.js', function () { });
    }
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    {"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Trinka AI","item":"https://www.trinka.ai/"},{"@type":"ListItem","position":2,"name":"Trinka Features","item":"https://www.trinka.ai/features/"},{"@type":"ListItem","position":3,"name":"Personal Dictionary","item":"https://www.trinka.ai/features/personal-dictionary"}]}
    `;

    this._renderer2.appendChild(this._document.head, script);

    let script1 = this._renderer2.createElement('script');
    script1.type = `application/ld+json`;
    script1.text = `
    {"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"The words that I add, can anybody other than me access it?","acceptedAnswer":{"@type":"Answer","text":"Your personal dictionary is only your own. No one else can access it or use it."}},{"@type":"Question","name":"Where can I find my Personal Dictionary?","acceptedAnswer":{"@type":"Answer","text":"In your Account Settings. You will see the My Dictionary button within the Writing Settings section."}},{"@type":"Question","name":"Can I share my dictionary with another account?","acceptedAnswer":{"@type":"Answer","text":"Your dictionary is only yours and cannot be shared with another account."}},{"@type":"Question","name":"Can I download my Personal Dictionary?","acceptedAnswer":{"@type":"Answer","text":"Currently, you cannot download your dictionary. However, we will be adding this feature soon."}}]}
    `;
    this._renderer2.appendChild(this._document.head, script1);

    let script2 = this._renderer2.createElement('script');
    script2.type = `application/ld+json`;
    script2.text = `
    {"@context":"https://schema.org","@type":"VideoObject","name":"My Dictionary - Trinka Product Walkthrough","description":"This is a product walkthrough series for the Trinka AI grammar checker tool. In this part, we will demonstrate how to review and edit the words stored in \"My...","thumbnailUrl":"https://i.ytimg.com/vi/5KsBh4PQUPI/mqdefault.jpg","duration":"PT55S","contentUrl":"https://www.youtube.com/watch?v=5KsBh4PQUPI","embedUrl":"https://www.youtube.com/embed/5KsBh4PQUPI","uploadDate":"2021-03-21","potentialAction":{"@type":"SeekToAction","target":"https://youtu.be/5KsBh4PQUPI?t={seek_to_second_number}","startOffset-input":"required name=seek_to_second_number"}}
    `;
    this._renderer2.appendChild(this._document.head, script2);
    ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      $.getScript('../../../../assets/js/home_client_slider.js', function () { });
      $('#closedPopup, #wordPluginPopup').click(function () {
        $('iframe.youtubeVideoForTryDemo').remove()
      });

      $('#VewiVideoSection').click(function () {
        if (!$('#iframe').length) {
          $('#iframeHolder').html('<iframe class="youtubeVideoForTryDemo" id="iframe" src="https://www.youtube.com/embed/5KsBh4PQUPI" width="100%" height="450"></iframe>');
        }
      });

    }
  }

  
}
