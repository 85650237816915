import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LinkService } from '../../_services/langhfre.service';
import * as $ from 'jquery';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';

declare function ire(string,{}): any;

@Component({
  selector: 'app-journal-finder-tools-page',
  templateUrl: './journal-finder-tools-page.component.html',
  styleUrls: ['./journal-finder-tools-page.component.scss']
})
export class JournalFinderToolsPageComponent implements OnInit {
  currentUrl: string = '';
  constructor(
    private router: Router,
    private metaTagService: Meta,
    private titleService: Title,
    private LinkService: LinkService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Find the Best Journal for Your Research | Free Journal Finder & Suggester");
    this.metaTagService.updateTag({ name: 'description', content: "Discover the best journal for your manuscript with Trinka's free Journal Finder. Get tailored suggestions to find the right journal for your research. Try it now!" });
    this.metaTagService.updateTag({ property: 'og:title', content: "Find the Best Journal for Your Research | Free Journal Finder & Suggester" });
    this.metaTagService.updateTag({ property: 'og:description', content: "Discover the best journal for your manuscript with Trinka's free Journal Finder. Get tailored suggestions to find the right journal for your research. Try it now!" });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/journal-finder' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Find the Best Journal for Your Research | Free Journal Finder & Suggester' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Discover the best journal for your manuscript with Trinka's free Journal Finder. Get tailored suggestions to find the right journal for your research. Try it now!" });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/journal-finder' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/journal-finder' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/journal-finder' });
 
    ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      $('#closedPopup, #wordPluginPopup').click(function () {
        $('iframe.youtubeVideoForTryDemo').remove()
      });

      $('#VewiVideoSection').click(function () {
        if (!$('#iframe').length) {
          $('#iframeHolder').html('<iframe class="youtubeVideoForTryDemo" id="iframe" src="https://www.youtube.com/embed/fuWNwHYFPFM" width="100%" height="450"></iframe>');
        }
      });

    }
  }

  

}
