import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { LinkService } from '../../../_services/langhfre.service';
import { Title, Meta } from '@angular/platform-browser';
import * as $ from 'jquery';
declare function ire(string,{}): any;
@Component({
  selector: 'app-thesis-checker',
  templateUrl: './thesis-checker.component.html',
  styleUrls: ['./thesis-checker.component.scss']
})
export class ThesisCheckerComponent implements OnInit, AfterViewInit {


  constructor(private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private LinkService: LinkService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Free Thesis Checker - Online Thesis Checking tool ');
    this.metaTagService.updateTag({ name: 'description', content: "Trinka's Thesis Checker is based on a advanced AI which checks your thesis for grammar and language errors so you can submit your thesis confidently." });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Free Thesis Checker - Online Thesis Checking tool' });
    this.metaTagService.updateTag({ property: 'og:description', content: "Trinka's Thesis Checker is based on a advanced AI which checks your thesis for grammar and language errors so you can submit your thesis confidently." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/thesis-checker' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/thesis-check.png' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Free Thesis Checker - Online Thesis Checking tool' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Trinka's Thesis Checker is based on a advanced AI which checks your thesis for grammar and language errors so you can submit your thesis confidently." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://trinka.ai/assets/images/thesis-check.png' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/thesis-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/thesis-checker' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/thesis-checker' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/thesis-checker' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/thesis-checker' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/thesis-checker' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/thesis-checker' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/thesis-checker' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/thesis-checker' });

    if (isPlatformBrowser(this.platformId)) {
      $.getScript('../../../assets/js/owl.carousel.min.js', function () { });
    }
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    {"@context":"https://schema.org","@graph":[{"@type":"WebPage","@id":"https://trinka.ai/thesis-checker/#webpage","url":"https://trinka.ai/thesis-checker/","name":"Free Thesis Checker - Online Thesis Checking tool","isPartOf":{"@id":"https://trinka.ai/#website"},"datePublished":"2022-07-09T05:22:52+00:00","dateModified":"2023-02-10T15:18:24+00:00","description":"Trinka's Thesis Checker is based on a advanced AI which checks your thesis for grammar and language errors so you can submit your thesis confidently.","inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://trinka.ai/thesis-checker/"]}]},{"@type":"WebSite","@id":"https://trinka.ai/#website","url":"https://trinka.ai/","name":"Trinka AI","description":"Advanced Grammar Checker for Academic and Professional Writing - Trinka","publisher":{"@id":"https://trinka.ai/#organization"},"alternateName":"The Complete Writing Assistant","inLanguage":"en-US"},{"@type":"Organization","@id":"https://trinka.ai/#organization","name":"Trinka AI","url":"https://trinka.ai/","logo":{"@type":"ImageObject","inLanguage":"en-US","@id":"https://trinka.ai/assets/images/trinka-logo.png","url":"https://trinka.ai/assets/images/trinka-logo.png","contentUrl":"","caption":"Trinka AI"},"image":{"@type":"ImageObject","@id":"https://trinka.ai/assets/images/"},"sameAs":["https://www.facebook.com/thetrinkaai/","https://twitter.com/thetrinkaai","https://www.linkedin.com/company/thetrinkaai/","https://www.youtube.com/@Trinka-ai"]}]}
    `;

    this._renderer2.appendChild(this._document.head, script);

    let script1 = this._renderer2.createElement('script');
    script1.type = `application/ld+json`;
    script1.text = `
    {"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Trinka AI","item":"https://www.trinka.ai/"},{"@type":"ListItem","position":2,"name":"Thesis Checker","item":"https://www.trinka.ai/thesis-checker"}]}
    `;

    this._renderer2.appendChild(this._document.head, script1);

    let script2 = this._renderer2.createElement('script');
    script2.type = `application/ld+json`;
    script2.text = `
    {"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"Does the Trinka thesis grammar checker work for aspiring medical students?","acceptedAnswer":{"@type":"Answer","text":"Yes. Trinka has been trained on an extremely large set of well-written papers—a substantial number of these papers belong to various fields in medicine, ranging from surgery and cardiology to pharmacokinetics and psychiatry. Trinka provides contextual suggestions based on the discipline of your choice."}},{"@type":"Question","name":"Is the Trinka thesis grammar check better than human editing services?","acceptedAnswer":{"@type":"Answer","text":"There are positives and negatives of using an AI editor. With an AI editor, you have speed, control and the best part—it’s completely free! AI editors support human editors who can organize your writing into a clear, logical, elegant paper. After letting the AI editor edit your paper, if you feel you need a human check, we do have in in-house experts copyeditors who provide assistance with reviewing your final documents."}},{"@type":"Question","name":"What do I get when I upload my manuscript using Proofread File?","acceptedAnswer":{"@type":"Answer","text":"The Proofread File service gives you’re the following benefits: The edited file will display all changes in track changes. This means you can review the changes and accept or reject them using Microsoft Word or any other compatible word processor. This file will also include comments that will help make your writing clear. You will also receive a detailed report which shows the number of revisions Trinka has made in each language category and an overall writing quality score."}}]}
    `;

    this._renderer2.appendChild(this._document.head, script2);
    ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
    
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      $.getScript('../../../assets/js/home_client_slider.js', function () { });
    }
  }

  
}
