import { Component, OnInit, Inject, PLATFORM_ID, HostListener } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { WebsiteService } from 'src/app/_services/website.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadScriptService } from 'src/app/_services/load-script.service';

declare var $: any;
declare const sourceMediumData: any;

import * as $ from 'jquery';
// import * as AOS from 'aos';
import { WINDOW } from '@ng-toolkit/universal';
import { style } from '@angular/animations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  notSticky: boolean = false;
  contactForm: FormGroup;
  formSection: boolean = true;
  thankSection: boolean = false;
  country: string;
  isSubmitting: boolean = false;
  isMenuActive: boolean = false;
  isBackdropActive: boolean = false;
  constructor(
    private toastr: ToastrService,
    private networkCall: WebsiteService,
    private fb: FormBuilder,
    private router: Router,
    @Inject(WINDOW) private window: Window,
    @Inject(PLATFORM_ID) private platformId: Object,
    public loadScript: LoadScriptService,
    private loadScriptService: LoadScriptService,
  ) {
    this.contactForm = fb.group({
      'contactFormName': ['', Validators.required],
      'contactFormEmail': ['', Validators.compose([Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)])],
      'contactFormSubjects': ['', Validators.required],
      'contactFormMessage': ['', Validators.required],
      'contactphoneNo': ['', Validators.required],
      'NatureBusinessType': ['', Validators.required],
      'DesignationTypes': ['', Validators.required],
    });
   }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      $(".dropdown-toggle").hover(function(){
        $('.dropdown-menu').css("opacity", "1");
        $('.dropdown-menu').css("visibility", "visible");
      });
      $('.menu-card a').click(function(){
        $('.dropdown-menu').css("opacity", "0");
        $('.dropdown-menu').css("visibility", "hidden");
      });
    }
  }

  closeCanvasMenu() {
    this.isBackdropActive = false;
    const offCanvasMenu = document.querySelector('.navbar-collapse.offcanvas');
    if (offCanvasMenu) {
      offCanvasMenu.classList.add('hiding');
      setTimeout(() => {
        this.isMenuActive = false;
        offCanvasMenu.classList.remove('hiding', 'show');
      }, 300);
    }
  }
  
  toggleOffCanvasMenu() {
    const offCanvasMenu = document.querySelector('.navbar-collapse.offcanvas');
    this.isBackdropActive = true;
    if (!this.isMenuActive) {
      offCanvasMenu?.classList.add('showing');
      setTimeout(() => {
        this.isMenuActive = true;
        offCanvasMenu?.classList.remove('showing');
        offCanvasMenu?.classList.add('show');
      }, 300);
    } else {
      this.closeCanvasMenu();
    }
  }

  toggleClass(event: Event): void {
    const allPTags = document.querySelectorAll('.nav-link.dropdown-toggle');
    
    // Remove 'show' class from all <p> tags
    allPTags.forEach((pTag) => {
      pTag.classList.remove('show');
    });
  
    // Add 'show' class to the clicked <p> tag
    const clickedPTag = event.target as HTMLElement;
    clickedPTag.classList.toggle('show');
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.notSticky = window.pageYOffset >= 100;
  }

  generateLinkWithSourceMedium(isSignupLink: boolean = true): string {
    //TODO: Change uat.cloud.trinka.ai to cloud.trinka.ai when going live
    let redirectLink = `https://cloud.trinka.ai/${isSignupLink ? 'signup' : 'signin'}?_rg=GB`;
    // if (sourceMediumData.source) {
    try {
      redirectLink += `&source=${sourceMediumData.source}`;
      redirectLink += `&medium=${sourceMediumData.medium}`;
    } catch (e) {
      redirectLink += `&source=''`;
      redirectLink += `&medium=''`;
    }

    // }
    // if (sourceMediumData.medium) {
      // redirectLink += `&medium=${sourceMediumData.medium}`;
    // }
    return redirectLink;
  }

  sectionValue(section: string) {
    this.formSection = true;
    this.thankSection = false;
  }

  sendContactUsForm() {
    if (this.contactForm.valid) {
      this.isSubmitting = true;
      if (localStorage.getItem("country-trinka") == null || localStorage.getItem("country-trinka") == "") {
        this.loadGeoAPIScript();
      }
      this.country = localStorage.getItem("country-trinka") ? localStorage.getItem("country-trinka") : "en";
      var query = this.contactForm.value.NatureBusinessType + "||" + this.contactForm.value.contactFormSubjects + "||" + this.contactForm.value.contactFormMessage + "||" + this.contactForm.value.contactFormName + "||" + this.contactForm.value.DesignationTypes + "||" + this.contactForm.value.contactphoneNo
      this.networkCall.postContactUs(this.contactForm.value.contactFormEmail, query, this.router.url).subscribe(result => {
        this.formSection = false;
        this.thankSection = true;
        this.country;
        this.toastr.success("Thanks! We will contact you shortly.")
        this.contactForm.reset();
      }, error => {
        this.toastr.warning("Something went wrong!")
      }).add(() => {
        this.isSubmitting = false;
      });
    } else {
      this.validateAllFormFields(this.contactForm);
    }
  }

  loadGeoAPIScript() {
    if (isPlatformBrowser(this.platformId)) {
      var scriptName = "geoIP"
      this.loadScript.loadScript(scriptName, '//geoip-js.com/js/apis/geoip2/v2.1/geoip2.js')
        .then(() => {
          console.log(`${scriptName} loaded successfully`);
          var onSuccess = function (location) {
            localStorage.setItem("country-trinka", location['country']['iso_code'])
          };

          var onError = function (error) {
            localStorage.setItem("country-trinka", "IN")
          };
          try {
            //@ts-ignore
            geoip2.city(onSuccess, onError);
          } catch (error) {
            console.log(error)
          }
        })
        .catch(() => {
          console.error(`Failed to load ${scriptName}`);
        });
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  // closeDropdown() {
  //   document.getElementById('#planPricing').style.display = "none";
  //   alert('3432432')
  // }

}
