<div class="trinka_cloud_wrap">
    <div id="particles-js"></div>
    <section class="mainTopBanner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="topBannerWrapper">
                        <div class="top-banner-left">
                            <h1>Personal Dictionary</h1>
                            <h2>Customize Trinka with 
                                Your Own Dictionary</h2>
                            <p>Avoid spelling alerts for new terms with your own dictionary. Add words that Trinka doesn't know, like brand names or new techniques, and Trinka will remember them wherever you write.</p>
                            <div class="btn-group">
                                <a href="https://cloud.trinka.ai/signup?_rg=GB" class="filled-btn" target="_blank">Get Started</a>
                            </div>
                        </div>
                        <div class="top-banner-right">
                            <img src="../../../assets/images/Personal-Dictionary.png" alt="personal dictionary in trinka">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="howItWorks section-pd list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="list-wrapper-image">
                        <a href="" data-toggle="modal" data-target="#wordPluginPopup" id="VewiVideoSection">
                            <img src=".././../../assets/images/MyDicitonayThumbnail.png" loading="lazy" decoding="async" alt="personal dictionary" class="img-fluid eposter-img">
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="list-wrapper-content-2">
                        <div class="trinka-title mb-0">
                            <h2>See How It Works</h2>
                            <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                            <p>Check out the easy walk-through video to start building your Personal Dictionary in no time.</p>
                            <p>It is a detailed step-by-step tutorial to help you use Trinka to its fullest.</p>
                            <p>Have more questions? Browse through our FAQs <a style="color: #0000ff;" (click)="scroll(target)" class="link text-link">here</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="writeToolboxs_section section-pd pb-0">
        <div class="trinka_container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Key Features of Trinka’s Personal Dictionary</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>Get rid of unnecessary word flagging and make your writing experience seamless.</p>
                    </div>
                </div>
            </div>
        </div>

        <section class="section-pd list-wrapper-style-2">
            <div class="trinka_container">
                <div class="row align-items-center flex-row-reverse">
                    <div class="col-md-6">
                        <div class="list-wrapper-content-2">
                            <h3>Categorization of Words</h3>
                            <p>You can organize the words you add to your dictionary as correct spellings, technical procedure names, biological names, product names, etc. Trinka gets better as you do.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="list-wrapper-image">
                            <img src="../../../assets/images/correct-spelling.png" loading="lazy" decoding="async" alt="Categorization of Words" class="img-fluid md50">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="list-wrapper-style-2 pt-0">
            <div class="trinka_container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="list-wrapper-content-2 pr-80">
                            <h3>Adjust Case Sensitivity</h3>
                            <p>You can even control whether Trinka should ignore your dictionary terms when they match casing or regardless.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="list-wrapper-image features-img">
                            <img src="../../../assets/images/case-sensitive.png" loading="lazy" decoding="async" alt="adjust case sensitive" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section-pd list-wrapper-style-2">
            <div class="trinka_container">
                <div class="row align-items-center flex-row-reverse">
                    <div class="col-md-6">
                        <div class="list-wrapper-content-2">
                            <h3>Choose English Type</h3>
                            <p>Trinka lets you control whether added terms are ignored when the English type matches or are ignored
                                regardless.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="list-wrapper-image">
                            <img src="../../../assets/images/american-english.png" loading="lazy" decoding="async" alt="Choose English Type" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="list-wrapper-style-2 pt-0">
            <div class="trinka_container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="list-wrapper-content-2 pr-80">
                            <h3>Sync Across Platforms</h3>
                            <p>Synced in real-time across all platforms, Trinka's dictionary seamlessly works in Cloud editor, MS Word Add-in, and Browser Plug-in.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="list-wrapper-image features-img">
                            <img src="../../../assets/images/SyncAcrossPlatforms.png" loading="lazy" decoding="async" alt="Sync Across Platforms" class="img-fluid" style="max-width: 300px;">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section-pd list-wrapper-style-2">
            <div class="trinka_container">
                <div class="row align-items-center flex-row-reverse">
                    <div class="col-md-6">
                        <div class="list-wrapper-content-2">
                            <h3>Maintain Brand<br/> Tone <span class="beta-badges">Enterprise</span></h3>
                            <p>Add your organization lexicon and new terms to stay on brand across all communications & personalize your writing assistance.</p>    
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="list-wrapper-image">
                            <img src="../../../assets/images/MyDictionary_Brand-tone.png" loading="lazy" decoding="async" alt="maintain brand tone" class="img-fluid" style="max-width: 320px;">
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div class="trinka-banner section-pd-sm">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-8">
                    <div class="trinka-title text-center">
                        <h3 class="text-white">Personalize Your Writing with Custom Dictionary</h3>
                    </div>
                    <div class="btn-group justify-content-center">
                        <a href="https://cloud.trinka.ai/signup?_rg=GB" class="white-btn" target="_blank">Get Started</a>
                    </div>
                </div>
            </div>  
        </div>
    </div>

    <app-safe-secure></app-safe-secure>

    <div class="faqQuestionAnwser section-pd pt-0" #target>
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10">
                    <div class="trinka-title text-center">
                        <h2>Frequently Asked Questions</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="faqQuestionsList">
                <div class="accordion" id="faq">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="faqContainer">
                                <div class="card">
                                    <div class="card-header" id="QuestionOne">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionOneContent" aria-expanded="true" aria-controls="QuestionOneContent">
                                            The words that I add, can anybody other than me access it? </a>
                                    </div>
    
                                    <div id="QuestionOneContent" class="collapse" aria-labelledby="QuestionOne" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Your personal dictionary is only your own. No one else can access it or use it.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="QuestionTwo">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionTwoContent" aria-expanded="true"
                                            aria-controls="QuestionTwoContent">Where can I find my Personal Dictionary? </a>
                                    </div>
    
                                    <div id="QuestionTwoContent" class="collapse" aria-labelledby="QuestionTwo" data-parent="#faq">
                                        <div class="card-body">
                                            <p>In your <a href="https://cloud.trinka.ai/dashboard/profile" target="_blank">Account Settings</a>. You will see the My Dictionary button within the Writing Settings section. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="faqContainer">
                                <div class="card">
                                    <div class="card-header" id="QuestionThree">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionThreeContent" aria-expanded="true"
                                            aria-controls="QuestionThreeContent">
                                            Can I share my dictionary with another account? 
                                        </a>
                                    </div>
    
                                    <div id="QuestionThreeContent" class="collapse" aria-labelledby="QuestionThree" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Your dictionary is only yours and cannot be shared with another account.  </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="QuestionFour">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionFourContent" aria-expanded="true"
                                            aria-controls="QuestionFourContent">
                                            Can I download my Personal Dictionary? 
                                        </a>
                                    </div>
    
                                    <div id="QuestionFourContent" class="collapse" aria-labelledby="QuestionFour" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Currently, you cannot download your dictionary. However, we will be adding this feature soon.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="otherApps section-pd-sm">
        <div class="trinka_container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="trinka-title text-center">
                        <h3 class="text-white">Use Trinka in Other Apps</h3>
                    </div>
                    <ul>
                        <li>
                            <a [routerLink]="['/trinkacloud']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Other_apps_TrinkaCloud.svg" loading="lazy" decoding="async" alt="trinka cloud">
                                <h3>Cloud</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/wordplugin']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Others_App_MSWord.svg" loading="lazy" decoding="async" alt="ms word">
                                <h3>MS Word Add-in</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/browser-plugin']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/other_Apps_browser.svg" loading="lazy" decoding="async" alt="browser plug-in">
                                <h3>Browser Plug-in</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/enterprise']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Other_Apps_enterprise.svg" loading="lazy" decoding="async" alt="enterprise">
                                <h3>Enterprise <small>(API, JS, & On-Premise)</small></h3>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="wordPluginPopup" tabindex="-1" role="dialog" aria-labelledby="wordPluginLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body" style="padding: 0;">
                <button type="button" id="closedPopup" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div id="iframeHolder"></div>

                <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/5KsBh4PQUPI"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                <!-- <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/z-TVcRwVp3A"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe> -->
            </div>
        </div>
    </div>
</div>