import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { LinkService } from '../../_services/langhfre.service';
import * as $ from 'jquery';
declare function ire(string,{}): any;

@Component({
  selector: 'app-windows-desktop-app',
  templateUrl: './windows-desktop-app.component.html',
  styleUrls: ['./windows-desktop-app.component.scss']
})
export class WindowsDesktopAppComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private LinkService: LinkService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Windows Desktop App');
    this.metaTagService.updateTag({ name: 'description', content: 'Windows Desktop App' });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Windows Desktop App' });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Windows Desktop App' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/windows-desktop-app' });
    this.metaTagService.updateTag({ name: 'robots', content: 'noindex, nofollow' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/windows-desktop-app' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/windows-desktop-app' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/windows-desktop-app' });
    
    ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }

}
