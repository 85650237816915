import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import * as $ from 'jquery';
import { LinkService } from '../../../_services/langhfre.service';
declare function ire(string,{}): any;
@Component({
  selector: 'app-citation-checker',
  templateUrl: './citation-checker.component.html',
  styleUrls: ['./citation-checker.component.scss']
})
export class CitationCheckerComponent implements OnInit, AfterViewInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private LinkService: LinkService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Trinka Citation Checker | Get Automated Citation Analysis');
    this.metaTagService.updateTag({ name: 'description', content: "Trinka's Citation Checker will help you cite strong papers, avoid negative reviewer feedback, and improve the quality of your argumentation." });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Trinka Citation Checker | Get Automated Citation Analysis' });
    this.metaTagService.updateTag({ property: 'og:description', content: "Trinka's Citation Checker will help you cite strong papers, avoid negative reviewer feedback, and improve the quality of your argumentation." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/features/citation-checker' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/features/s2.svg' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Trinka Citation Checker | Get Automated Citation Analysis' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Trinka's Citation Checker will help you cite strong papers, avoid negative reviewer feedback, and improve the quality of your argumentation." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://trinka.ai/assets/images/features/s2.svg' });
    this.metaTagService.updateTag({ name: 'keywords', content: 'citation checker, reference checker, apa checker, apa citation checker, check citation online, apa format checker' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/features/citation-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/features/citation-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/features/citation-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/features/citation-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/features/citation-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/features/citation-checker' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/features/citation-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/features/citation-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/features/citation-checker' });
    if (isPlatformBrowser(this.platformId)) {
      // $.getScript('../../../assets/js/particles.min.js', function(){});
      //$.getScript('../../../assets/js/wisepop.js', function () { });
    }
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    {"@context":"https://schema.org","@graph":[{"@type":"WebPage","@id":"https://trinka.ai/features/citation-checker/","url":"https://trinka.ai/features/citation-checker/","name":"Trinka Citation Checker | Get Automated Citation Analysis","isPartOf":{"@id":"https://trinka.ai/#website"},"datePublished":"2022-06-09T05:22:52+00:00","dateModified":"2023-02-08T15:18:24+00:00","description":"Trinka's Citation Checker will help you cite strong papers, avoid negative reviewer feedback, and improve the quality of your argumentation.","inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://trinka.ai/features/citation-checker"]}]},{"@type":"WebSite","@id":"https://trinka.ai/#website","url":"https://trinka.ai/","name":"Trinka AI","description":"Advanced Grammar Checker for Academic &amp; Professional Writing - Trinka","publisher":{"@id":"https://trinka.ai/#organization"},"alternateName":"Your Complete Writing Assistant","inLanguage":"en-US"},{"@type":"Organization","@id":"https://trinka.ai/#organization","name":"Trinka AI","url":"https://trinka.ai/","logo":{"@type":"ImageObject","inLanguage":"en-US","@id":"https://trinka.ai/assets/images/trinka-logo.png","url":"https://trinka.ai/assets/images/trinka-logo.png","contentUrl":"","caption":"Trinka AI"},"image":{"@type":"ImageObject","@id":"https://trinka.ai/assets/images/"},"sameAs":["https://www.facebook.com/thetrinkaai/","https://twitter.com/thetrinkaai","https://www.linkedin.com/company/thetrinkaai/","https://www.youtube.com/@Trinka-ai"]}]}
    `;
    this._renderer2.appendChild(this._document.head, script);

    let script1 = this._renderer2.createElement('script');
    script1.type = `application/ld+json`;
    script1.text = `
    {"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Trinka Ai","item":"https://www.trinka.ai/"},{"@type":"ListItem","position":2,"name":"Trinka Features","item":"https://www.trinka.ai/features"},{"@type":"ListItem","position":3,"name":"Citation Checker","item":"https://www.trinka.ai/features/citation-checker"}]}
    `;
    this._renderer2.appendChild(this._document.head, script1);

    let script2 = this._renderer2.createElement('script');
    script2.type = `application/ld+json`;
    script2.text = `
    {"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"How does Trinka Citation Checker work?","acceptedAnswer":{"@type":"Answer","text":"Improve the quality of your citations in 3 simple steps! Just Sign Up for free on Trinka. Go to the Citation Checker page from the left navigation panel. Upload your document. You can upload from Google Drive, Dropbox, or your computer. You can also use document already uploaded on Trinka. You will get your report in a few minutes!"}},{"@type":"Question","name":"Where can I access Trinka Citation Checker?","acceptedAnswer":{"@type":"Answer","text":"Trinka Citation Checker feature is currently available only on the Trinka Cloud platform to all users. It will come soon to the Word add-in too."}},{"@type":"Question","name":"Does Trinka review citation to paywalled articles?","acceptedAnswer":{"@type":"Answer","text":"Yes. We can quality check your citations even if they are published in paywalled journals."}},{"@type":"Question","name":"Can I share the citation report with my co-authors?","acceptedAnswer":{"@type":"Answer","text":"Currently, the report is not shareable. We will make that happen in the future!"}}]}
    `;
    this._renderer2.appendChild(this._document.head, script2);

    let script3 = this._renderer2.createElement('script');
    script3.type = `application/ld+json`;
    script3.text = `
    {"@context":"http://schema.org","@type":"VideoObject","name":"Free Citation Checker | Get Citation Analysis for Free - Trinka Product Walkthrough","description":"Trinka Citation Checker identifies various quality risks by performing automated citation analysis that lets you improve your citation list and strengthen your research argumentation.  Trinka Citation Checker performs automated citation analysis to identify citations that can make your research premise and argumentation weaker and helps you cite credible, strong citations for your paper.  Check Now : https://www.trinka.ai/features/citation-checker  Join us on our Social Channels  Twitter - https://twitter.com/thetrinkaai  Instagram - https://www.instagram.com/thetrinkaai/  Facebook - https://www.facebook.com/thetrinkaai  LinkedIn - https://www.linkedin.com/company/thetrinkaai/  #citation #citations #writing #grammarchecker","thumbnailUrl":"https://i.ytimg.com/vi/XBuFdCM3xtk/default.jpg","uploadDate":"2022-08-02T06:13:06Z","duration":"PT2M2S","embedUrl":"https://www.youtube.com/embed/XBuFdCM3xtk","interactionCount":"726"}
    `;
    this._renderer2.appendChild(this._document.head, script3);
    ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      $('#closedPopup, #wordPluginPopup').click(function () {
        $('iframe.youtubeVideoForTryDemo').remove()
      });

      $('#VewiVideoSection').click(function () {
        if (!$('#iframe').length) {
          $('#iframeHolder').html('<iframe class="youtubeVideoForTryDemo" id="iframe" src="https://www.youtube.com/embed/XBuFdCM3xtk" width="100%" height="450"></iframe>');
        }
      });

    }
  }

  
}
