import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { WebsiteService } from 'src/app/_services/website.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import * as $ from 'jquery';
import { LinkService } from '../../../_services/langhfre.service';
import { Router } from '@angular/router';
import { LoadScriptService } from 'src/app/_services/load-script.service';
declare function ire(string,{}): any;
@Component({
  selector: 'app-inclusive-language-report',
  templateUrl: './inclusive-language-report.component.html',
  styleUrls: ['./inclusive-language-report.component.scss']
})
export class InclusiveLanguageReportComponent implements OnInit {
  email: any;
  // emailEnterprise : FormGroup;
  contactForm: FormGroup;
  formSection: boolean = true;
  thankSection: boolean = false;
  country: string;
  lqr_Correctness: boolean = false;
  lqr_Readability: boolean = false;
  lqr_Consistency: boolean = false;
  lqr_Conciseness: boolean = false;
  lqr_FormalTone: boolean = false;
  lqr_InclusiveLanguage: boolean = false;
  lqr_CodeWords: boolean = false;
  lqr_GenderCode: boolean = true;
  status: boolean = false;
  isSubmitting: boolean = false;
  
  constructor(
    private toastr: ToastrService,
    private networkCall: WebsiteService,
    private fb: FormBuilder,
    private titleService: Title,
    private metaTagService: Meta,
    private LinkService: LinkService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private router: Router,
    public loadScript: LoadScriptService
  ) {
    this.contactForm = fb.group({
      'contactFormName': ['', Validators.required],
      'contactFormEmail': ['', Validators.compose([Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)])],
      'contactFormSubjects': ['', Validators.required],
      'contactFormMessage': ['', Validators.required],
      'contactphoneNo': ['', Validators.required],
      'NatureBusinessType': ['', Validators.required],
      'DesignationTypes': ['', Validators.required],
    });
   }

  ngOnInit(): void {
    this.titleService.setTitle(' Inclusive Language Check | Make Your Communication Bias Free ');
    this.metaTagService.updateTag({ name: 'description', content: " Make your writing inclusive and enhance audience engagement with our expert tips. Elevate your DEIB (Diversity, Equity, Inclusion, and Belonging) goals with impactful writing strategies." });
    this.metaTagService.updateTag({ property: 'og:title', content: ' Inclusive Language Check | Make Your Communication Bias Free ' });
    this.metaTagService.updateTag({ property: 'og:description', content: ' Make your writing inclusive and enhance audience engagement with our expert tips. Elevate your DEIB (Diversity, Equity, Inclusion, and Belonging) goals with impactful writing strategies.' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/features/inclusive-language-check' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/bias-banner.svg' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: ' Inclusive Language Check | Make Your Communication Bias Free' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: " Make your writing inclusive and enhance audience engagement with our expert tips. Elevate your DEIB (Diversity, Equity, Inclusion, and Belonging) goals with impactful writing strategies." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/bias-banner.svg' });
    this.metaTagService.updateTag({ name: 'keywords', content: 'inclusive language checker, Microsoft Word Inclusivity Checker, best inclusive language tool, Inclusive Language Checker, Inclusive language checker online, Inclusive language checker free, microsoft word inclusive language checker, bias language checker, gender-inclusive language examples' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/features/inclusive-language-check' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/features/inclusive-language-check' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/features/inclusive-language-check' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/features/inclusive-language-check' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/features/inclusive-language-check' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/features/inclusive-language-check' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/features/inclusive-language-check' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/features/inclusive-language-check' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/features/inclusive-language-check' });
    if (isPlatformBrowser(this.platformId)) {
      // $.getScript('../../../assets/js/particles.min.js', function(){});
      $.getScript('../../../../assets/js/wisepop.js', function () { });
      this.country = localStorage.getItem("country")? localStorage.getItem("country"):"en";
    }
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    {"@context":"https://schema.org","@graph":[{"@type":"WebPage","@id":"https://www.trinka.ai/features/inclusive-language-check#webpage","url":"https://www.trinka.ai/features/inclusive-language-check","name":"Inclusive Language Check | Make Your Communication Bias Free","isPartOf":{"@id":"https://www.trinka.ai#website"},"datePublished":"2022-07-09T05:22:52+00:00","dateModified":"2023-02-10T15:18:24+00:00","description":"Make your writing inclusive and enhance audience engagement with our expert tips. Elevate your DEIB (Diversity, Equity, Inclusion, and Belonging) goals with impactful writing strategies.","inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://www.trinka.ai/features/inclusive-language-check"]}]},{"@type":"WebSite","@id":"https://www.trinka.ai/#website","url":"https://www.trinka.ai","name":"Trinka AI","description":"Grammar Checker and AI proofreading and editing tool","publisher":{"@id":"https://www.trinka.ai#organization"},"alternateName":"The Complete AI Writing Assistant","inLanguage":"en-US"},{"@type":"Organization","@id":"https://www.trinka.ai/#organization","name":"Trinka AI","url":"https://www.trinka.ai","logo":{"@type":"ImageObject","inLanguage":"en-US","@id":"https://trinka.ai/assets/images/trinka-logo.png","url":"https://trinka.ai/assets/images/trinka-logo.png","contentUrl":"","caption":"Trinka AI"},"image":{"@type":"ImageObject","@id":"https://trinka.ai/assets/images/"},"sameAs":["https://www.facebook.com/thetrinkaai/","https://twitter.com/thetrinkaai","https://www.linkedin.com/company/thetrinkaai/","https://www.youtube.com/@Trinka-ai"]}]}
    `;
    this._renderer2.appendChild(this._document.head, script);

    let script1 = this._renderer2.createElement('script');
    script1.type = `application/ld+json`;
    script1.text = `
    {"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Trinka AI","item":"https://www.trinka.ai"},{"@type":"ListItem","position":2,"name":"Inclusive Language Check","item":"https://www.trinka.ai/features/inclusive-language-check"}]}
    `;
    this._renderer2.appendChild(this._document.head, script1);

    let script2 = this._renderer2.createElement('script');
    script2.type = `application/ld+json`;
    script2.text = `
    {"@context":"https://schema.org","@type":"Organization","name":"Trinka AI","url":"https://www.trinka.ai","logo":"https://www.trinka.ai/assets/images/trinka-logo.png","address":{"@type":"PostalAddress","streetAddress":"Office No 801, Techniplex – II Off SV Road","addressLocality":"Goregaon - West","addressRegion":"Mumbai","postalCode":"400062","addressCountry":"IN"},"sameAs":["https://www.facebook.com/thetrinkaai/","https://www.instagram.com/thetrinkaai/","https://twitter.com/thetrinkaai/","https://www.youtube.com/c/Trinka-ai","https://www.linkedin.com/company/thetrinkaai/",""]}
    `;
    this._renderer2.appendChild(this._document.head, script2);

    let script3 = this._renderer2.createElement('script');
    script3.type = `application/ld+json`;
    script3.text = `
    {"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"What is Trinka AI's Inclusive Language Check?","acceptedAnswer":{"@type":"Answer","text":"Trinka AI's Inclusive Language Check is an advanced tool that helps you identify and eliminate potential biases in your writing. It analyzes your content for six key categories of bias: gender, race, age, religion, disability, and sexual orientation and identity."}},{"@type":"Question","name":"How does Trinka AI's Inclusive Language Check work?","acceptedAnswer":{"@type":"Answer","text":"Trinka AI's Inclusive Language Check uses sophisticated natural language processing (NLP) algorithms to identify patterns and language indicators that may suggest potential biases. It then provides suggestions for how to rephrase your language to be more inclusive and respectful."}},{"@type":"Question","name":"Why is it important to use an inclusive language checker?","acceptedAnswer":{"@type":"Answer","text":"Using inclusive language is essential for creating a welcoming and respectful environment for all. By eliminating harmful biases in your writing, you can help to ensure that everyone feels included and valued."}},{"@type":"Question","name":"What are some examples of biased language?","acceptedAnswer":{"@type":"Answer","text":"Examples of biased language include gender bias, racial bias, age bias, religious bias, disability bias, and sexual orientation and identity bias."}},{"@type":"Question","name":"Is there an inclusive language checker for Microsoft Word?","acceptedAnswer":{"@type":"Answer","text":"Yes, Trinka AI's Inclusive Language Check will be available as a Word add-in soon. It seamlessly integrates with Microsoft Word to help you identify and eliminate potential biases in your writing."}}]}
    `;
    this._renderer2.appendChild(this._document.head, script3);
    ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      $('#closedPopup, #wordPluginPopup').click(function () {
        $('iframe.youtubeVideoForTryDemo').remove()
      });

      $('#VewiVideoSection').click(function () {
        if (!$('#iframe').length) {
          $('#iframeHolder').html('<iframe class="youtubeVideoForTryDemo" id="iframe" src="https://www.youtube.com/embed/weegWvlF0Ds" width="100%" height="450"></iframe>');
        }
      });

    }
  }

  sectionValue(section: string) {
    this.formSection = true;
    this.thankSection = false;
  }
  

  sendContactUsForm() {
    if (this.contactForm.valid) {
      this.isSubmitting = true;
      if (localStorage.getItem("country-trinka") == null || localStorage.getItem("country-trinka") == "") {
        this.loadGeoAPIScript();
      }
      this.country = localStorage.getItem("country-trinka") ? localStorage.getItem("country-trinka") : "en";
      var query = this.contactForm.value.NatureBusinessType + "||" + this.contactForm.value.contactFormSubjects + "||" + this.contactForm.value.contactFormMessage + "||" + this.contactForm.value.contactFormName + "||" + this.contactForm.value.DesignationTypes + "||" + this.contactForm.value.contactphoneNo
      this.networkCall.postContactUs(this.contactForm.value.contactFormEmail, query, this.router.url).subscribe(result => {
        this.formSection = false;
        this.thankSection = true;
        this.country;
        this.toastr.success("Thanks! We will contact you shortly.")
        this.contactForm.reset();
      }, error => {
        this.toastr.warning("Something went wrong!")
      }).add(() => {
        this.isSubmitting = false;
      });
    } else {
      this.validateAllFormFields(this.contactForm)
    }
  }

  loadGeoAPIScript() {
    if (isPlatformBrowser(this.platformId)) {
      var scriptName = "geoIP"
      this.loadScript.loadScript(scriptName, '//geoip-js.com/js/apis/geoip2/v2.1/geoip2.js')
        .then(() => {
          console.log(`${scriptName} loaded successfully`);
          var onSuccess = function (location) {
            localStorage.setItem("country-trinka", location['country']['iso_code'])
          };

          var onError = function (error) {
            localStorage.setItem("country-trinka", "IN")
          };
          try {
            //@ts-ignore
            geoip2.city(onSuccess, onError);
          } catch (error) {
            console.log(error)
          }
        })
        .catch(() => {
          console.error(`Failed to load ${scriptName}`);
        });
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  genderCodedTabe(){
    this.lqr_Correctness = false;
    this.lqr_Readability = false;
    this.lqr_Consistency = false;
    this.lqr_Conciseness = false;
    this.lqr_FormalTone = false;
    this.lqr_InclusiveLanguage = false;
    this.lqr_CodeWords = false;
    this.lqr_GenderCode = true;
    this.status= false;
  }
  correctnessTabe(){
    this.lqr_Correctness = true;
    this.lqr_Readability = false;
    this.lqr_Consistency = false;
    this.lqr_Conciseness = false;
    this.lqr_FormalTone = false;
    this.lqr_InclusiveLanguage = false;
    this.lqr_CodeWords = false;
    this.lqr_GenderCode = false;
    this.status= false;
  }
  ReadabilityTabe(){
    this.lqr_Correctness = false;
    this.lqr_Readability = true;
    this.lqr_Consistency = false;
    this.lqr_Conciseness = false;
    this.lqr_FormalTone = false;
    this.lqr_InclusiveLanguage = false;
    this.lqr_CodeWords = false;
    this.lqr_GenderCode = false;
    this.status= false;
  }
  ConsistencyTabe(){
    this.lqr_Correctness = false;
    this.lqr_Readability = false;
    this.lqr_Consistency = true;
    this.lqr_Conciseness = false;
    this.lqr_FormalTone = false;
    this.lqr_InclusiveLanguage = false;
    this.lqr_CodeWords = false;
    this.lqr_GenderCode = false;
    this.status= false;
  }
  ConcisenessTabe(){
    this.lqr_Correctness = false;
    this.lqr_Readability = false;
    this.lqr_Consistency = false;
    this.lqr_Conciseness = true;
    this.lqr_FormalTone = false;
    this.lqr_InclusiveLanguage = false;
    this.lqr_CodeWords = false;
    this.lqr_GenderCode = false;
    this.status= false;
  }
  FormalToneTabe(){
    this.lqr_Correctness = false;
    this.lqr_Readability = false;
    this.lqr_Consistency = false;
    this.lqr_Conciseness = false;
    this.lqr_FormalTone = true;
    this.lqr_InclusiveLanguage = false;
    this.lqr_CodeWords = false;
    this.lqr_GenderCode = false;
    this.status= false;
  }
  InclusiveLanguageTabe(){
    this.lqr_Correctness = false;
    this.lqr_Readability = false;
    this.lqr_Consistency = false;
    this.lqr_Conciseness = false;
    this.lqr_FormalTone = false;
    this.lqr_InclusiveLanguage = true;
    this.lqr_CodeWords = false;
    this.lqr_GenderCode = false;
    this.status= false;
  }
  CodeWordsTabe(){
    this.lqr_Correctness = false;
    this.lqr_Readability = false;
    this.lqr_Consistency = false;
    this.lqr_Conciseness = false;
    this.lqr_FormalTone = false;
    this.lqr_InclusiveLanguage = false;
    this.lqr_CodeWords = true;
    this.lqr_GenderCode = false;
    this.status = !this.status;     
  }

  

}
